import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsPopupFreeRoundsOpened,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode, updateTextScale } from '../../utils';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH, BgSkin } from '../background/config';
import {
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_PORTRAIT_HEIGHT,
  FEATURE_BTN_PORTRAIT_POSITION_Y_MARGIN,
  FEATURE_BTN_PORTRAIT_WIDTH,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  GAME_CONTAINER_WIDTH,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

import { BUY_TEXT_PIVOT_Y } from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  private windowWidth: number;

  private windowHeight: number;

  private isFreeRoundBonus: boolean;

  private isPopupFreeRoundsOpened = false;

  constructor() {
    super();
    this.setPosition(SAFE_AREA_LANDSCAPE_WIDTH, SAFE_AREA_LANDSCAPE_HEIGHT);
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);
    this.text.pivot.y = BUY_TEXT_PIVOT_Y;

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    this.isFreeRoundBonus = false;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.handleDisable(isFadeOut);
    });

    eventManager.addListener(EventTypes.SET_BROKEN_BUY_FEATURE, (isBroken: boolean) => {
      this.handleDisable(isBroken);
    });

    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });

    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable(isPopupFreeRoundsOpened);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.buttonMode = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.style = buyFeatureHoverTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);
        this.text.style = buyFeatureClickTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.btn.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.btn.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, 120, 100);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      AudioApi.play({ type: ISongs.Scatter_01 });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) {
      this.isDisabled = true;
      return;
    }
    if (this.isAutoSpinInProgress) return;

    updateTextScale(this.text, 120, 100);

    if (this.isAutoSpinInProgress) {
      this.btn.buttonMode = false;
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnDisable);
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );

      return;
    }

    this.isDisabled = disable;

    if (setIsPopupFreeRoundsOpened()) {
      this.isDisabled = false;
    }

    if (disable) {
      this.btn.buttonMode = false;
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnDisable);
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    } else {
      this.btn.buttonMode = true;
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.style = buyFeatureTextStyle;
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    if (width > height) {
      this.btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
      this.btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    } else {
      this.btn.width = FEATURE_BTN_PORTRAIT_WIDTH;
      this.btn.height = FEATURE_BTN_PORTRAIT_HEIGHT;
    }

    this.setPosition(width, height);
    this.text.position.set(this.btn.width / 2, this.btn.height / 2);

    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    if (width > height) {
      this.x = -272;
      this.y = -28.5;
    } else {
      this.x = GAME_CONTAINER_WIDTH / 2 - this.btn.width / 2 + FEATURE_BTN_PORTRAIT_POSITION_Y_MARGIN;
      this.y = SLOTS_CONTAINER_HEIGHT + FEATURE_BTN_LANDSCAPE_HEIGHT / 4;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
